<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary ">
          <b-row align-v="center">
            <b-col>
              <h1 class="">
                <feather-icon icon="FolderIcon" size="30" class="mr-50" />
                Prontuário de  {{ patient.physicalPerson.firstName }}
                  {{ patient.physicalPerson.lastName }}
              </h1>
              <!-- <h4 class="mt-1">Selecione um paciente:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <b-card>
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar size="70px" variant="light-danger" :text="getAbreviateByPatient(patient)" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ patient.physicalPerson.firstName }}
                  {{ patient.physicalPerson.lastName }}
                </h4>
                <span class="card-text">
                  {{
                    patient.physicalPerson.email || 'Email não cadastrado'
                  }}</span
                >
              </div>
              <div class="d-flex flex-wrap"></div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <div class="ml-0">
                <h5 class="mb-0">{{ getGender(patient.gender) }}</h5>
                <small>Gênero</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="ml-1">
                <h5 class="mb-0">{{ patient.occupation }}</h5>
                <small>Ocupação</small>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <div class="ml-0">
                <h5 class="mb-0">{{ patient.physicalPerson.cpf }}</h5>
                <small>CPF</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ getAgeByBirthday(patient.birthDate) }} anos
                </h5>
                <small>Idade</small>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <div class="ml-0">
                <h5 class="mb-0">
                  {{
                    patient.physicalPerson.person.phoneNumber ||
                    patient.physicalPerson.person.mobileNumber ||
                    'N/A'
                  }}
                </h5>
                <small>Telefone</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ patient.physicalPerson.person.email || 'N/A' }}
                </h5>
                <small>E-mail</small>
              </div>
            </div>
          </div>
          <h5 class="mt-3">Particularidades do Paciente</h5>
          <p>
            {{ patient.note }}
          </p>
          <div class="demo-inline-spacing mb-4">
            <b-form-checkbox
              v-model="patient.visitedPsy"
              name="check-button"
              switch
              inline
              disabled
            >
              Visitou um Psicólogo Antes?
            </b-form-checkbox>
            <b-form-checkbox
              v-model="patient.internment"
              name="check-button"
              switch
              inline
              disabled
            >
              Internamento
            </b-form-checkbox>

            <b-form-checkbox
              checked="false"
              name="check-button"
              switch
              inline
              disabled
            >
              Internamento
            </b-form-checkbox>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="button-right"
            :to="'/pacientes/' + pacienteId"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card>
          <h1 class="lg-1">Histórico do Paciente</h1>
          <app-timeline>
            <app-timeline-item
              v-for="history in patientHistory"
              :key="history.id"
            >
              <div
                class="
                  d-flex
                  flex-sm-row flex-column flex-wrap
                  justify-content-between
                  mb-1 mb-sm-0
                "
              >
                <h6>{{ getTypeOperationByHistory(history) }}</h6>
                <small class="text-muted">{{
                  getFromNowByDate(history.createdAt)
                }}</small>
              </div>
              <!-- <p>Devolutiva atualizada</p>   TODO RESOLVER -->
            </app-timeline-item>
          </app-timeline>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            block
            variant="outline-primary"
          >
            Visualizar histórico completo
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <h1>Prontuário do Paciente</h1>
    <!--inicio card item prontuario-->
    <b-card>
      <div>
        <b-card
          class="item-aplicacao apl-grey"
          v-for="history in medicalRecords"
          :key="history.id"
        >
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h5>Consulta {{ history.number }}</h5>
            <!-- <small class="text-muted data-apl"
              ><strong>
                TODO- INSERIR DATA
              </strong>
            </small> -->
            <div class="button-apl-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-toggle="'collapse-' +  history.number"
                
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </div>
          </div>
          <!--Pills com aplicações da consulta-->

          <b-badge
            v-for="type in history.consultationTypes"
            :key="type.id"
            pill
            :class="getClassColorPill(type.name)"
            class="mr-1"
          >
            {{ type.name }}
          </b-badge>
          <b-collapse :id="'collapse-'+ history.number" class="mt-2">
            <b-card class="border mb-0">
              <b-card-text class="card-text">
                <h5>Devolutiva:</h5>
                <p>
                  {{ history.comments }}
                </p>
              </b-card-text>

              <b-row>
                <div class="col text-right">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                  >
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Editar</span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :to="'/consulta/'+history.medicalAppointmentId"
                  >
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span class="align-middle">Visualizar</span>
                  </b-button>
                </div>
              </b-row>
            </b-card>
          </b-collapse>
        </b-card>
      </div>
    </b-card>

    <div class="modal-transparent container-modal">
      <b-modal
        id="modal-xsw"
        ok-only
        ok-title="Aceitar"
        centered
        size="xl"
        title="Extra Large Modal"
      >
        <b-row>
          <h2 class="h2-modal-sel">Selecione o tipo de consulta:</h2>
        </b-row>
        <b-row align-v="center" class="h-100">
          <b-col lg="4">
            <b-card class="card-consulta c-consulta-inicial">
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/1_consulta_inicial.png" />
              </div>
              <h3 class="txt-consulta-sel">Consulta Inicial</h3>
            </b-card>
          </b-col>
          <b-col lg="4">
            <b-card
              class="card-consulta c-testes-cognitivos"
              onclick="location.href='/NovoTesteCognitivo'"
            >
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/2_teste_cognitivos.png" />
              </div>
              <h3 class="txt-consulta-sel">Testes Cognitivos</h3>
            </b-card>
          </b-col>
          <b-col lg="4">
            <b-card class="card-consulta c-jogo-teste">
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/3_jogo_teste.png" />
              </div>
              <h3 class="txt-consulta-sel">Jogo Teste</h3>
            </b-card>
          </b-col>
          <b-col lg="4">
            <b-card class="card-consulta c-aplicacao-jogo">
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/5_aplicação do jogo.png" />
              </div>
              <h3 class="txt-consulta-sel">Aplicação do Jogo</h3>
            </b-card>
          </b-col>
          <b-col lg="4">
            <b-card class="card-consulta c-devolutiva">
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/4_devolutiva.png" />
              </div>
              <h3 class="txt-consulta-sel">Devolutiva</h3>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import { BFormTextarea, BCardText, BModal, VBModal } from 'bootstrap-vue'
import { BTabs, BTab } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import patientService from '@/services/patientService'
import utilsService from '@/services/utilsService'
import patientHistoryService from '@/services/patientHistoryService'
export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCardText,
    BFormTextarea,
    BTabs,
    BCardText,
    BTab,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BMedia,
    BAvatar,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BModal,

    BFormCheckbox,
  },

  props: ['pacienteId'],
  data: () => ({
    patientHistory: [],
    patient: {
      physicalPerson: {},
    },
    medicalRecords: [],
    sexos: ['Masculino', 'Feminino', 'Outros'],
    escolaridades: [
      'Ensino médio incompleto',
      'Ensino médio completo',
      'Ensino superior incompleto',
      'Ensino superior completo',
    ],
    meses: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    form: {
      nome: undefined,
    },
  }),
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    ...utilsService,
    onSubmit() {
      alert('Formulário Enviado')
    },

    async getPatientById() {
      const patient = await patientService.getById(this.pacienteId)
      this.patient = patient
    },
    async getProntuario() {
      const patientHistory = await patientHistoryService.getById(
        this.pacienteId
      )
      this.patientHistory = patientHistory
    },
    async getMedicalRecords() {
      const medicalRecords = await patientService.getMedicalRecordsByUserId(
        this.pacienteId
      )
      this.medicalRecords = medicalRecords
    },
    getGender(letter) {
      return utilsService.getByLetter(letter)
    },
    getTypeOperationByHistory(history) {
      return patientHistoryService.getTypeOperationByOperation(
        history.operationType
      )
    },
  },
  computed: {
    dias() {
      return Object.keys(new Array(31).fill(0)).map(i => parseInt(i) + 1)
    },
    anos() {
      return Object.keys(new Array(150).fill(0)).map(i => parseInt(i) + 1852)
    },
  },
  mounted() {
    if (this.pacienteId) {
      this.getPatientById()
      this.getProntuario()
      this.getMedicalRecords()
    }
  },
}
</script>
